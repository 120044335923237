/////React
import React, { useState, useEffect } from "react";

//Reac Dom
import { useLocation, useHistory } from "react-router-dom";

///Axios
import Axios from "axios";

//Helpers
import { getToken } from "../Helpers/auth-helpers";

////Layout Sucess
import Sucess from "../components/responses/Sucess";

/////Ant Design
import {
  Row,
  Col,
  Card,
  Divider,
  Form,
  Input,
  Radio,
  Image,
  Button,
  Modal,
  Select,
} from "antd";

/////Iconos Imagenes
import imgSolicitudForm from "../assets/images/empleo/onlinecsv.png";

////Locale
import "moment/locale/es";

///// Extras
const { Meta } = Card;
const { TextArea } = Input;
const { Option } = Select;
///Componente Form
const ConfirmarFormularioManejaEquipos = ({ usuario }) => {
  const [datosCarga, setdatosCarga] = useState(null);
  const [modalSucessDenegado, setModalSucessDenegado] = useState(false);
  const [modalConfirmacionOferta, setmodalConfirmacionOferta] = useState(false);
  ////History react Dom
  const history = useHistory();

  ////Estados para cargas de datos
  const [value, setValue] = useState(1);
  ////Fin Estados para cargas de datos

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  ////Formularios
  const [form] = Form.useForm();
  ////Fin Formularios

  ///carga de ID recibido
  const idRequerimiento = new URLSearchParams(useLocation().search).get("id");
  ///fin carga de ID recibido
  //console.log(">>> Id para cargado de entrevista Maneja Solo",idRequerimiento);

  ////carga Datos entrevista
  const cargaDatosPerfil = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}?populate[mother]=*`,
      { headers }
    );

    setdatosCarga(data);
    return data;
  };
  ////carga Datos entrevista

  ////carga Datos asignados para entrevista
  const consultaManejaEquipos = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/manejaequipos?filters[idMother][$eq]=${id}`,
      { headers }
    );

    return data.data[0];
  };
  ////carga Datos asignados para entrevista

  ////carga Datos asignados para entrevista
  const cargaDatosAsignados = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${id}`,
      { headers }
    );

    return data.data[0];
  };
  ////carga Datos asignados para entrevista

  const correoPerfilDeclinado = async () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const datosEnviar = {
      key: "OFRNDQDHNRX7PYY8IUP3GBB94EZSNX5N",
      email: datosCarga?.data.attributes.mother.data.attributes.correo,
      nombre: datosCarga?.data.attributes.nombre,
      asunto: "Terminación proceso aplicación plaza",
      mensaje:
        "Gracias por tu interés en aplicar con nosotros. Por el momento hemos decidido no continuar con tu proceso de selección, sin embargo agradecemos enormemente tu tiempo e interés en Pronto BPO, Te invitamos a que continúes pendiente de nuestras plazas y no dudes en aplicar si consideras llenas los requisitos",
    };

    const datos = JSON.stringify(datosEnviar);

    //console.log(datos, headers);
    const { data } = await Axios.post(
      `https://sendmail.prontoapps.app/api/denegar`,
      datos,
      { headers }
    );
    return data;
  };

  const denegarSolicitudEmpleo = async () => {
    const datosActualizar = JSON.stringify({
      data: {
        estado: "Declinado",
      },
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { dataStatus } = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}`,
      datosActualizar,
      { headers }
    );

    setModalSucessDenegado(true);

    console.log("Envio de actualizacion Estado", dataStatus);

    correoPerfilDeclinado();
  };

  /////Carga de los Datos en el muro
  const onFill = (data, dataRCA, dataEntre) => {
    console.log("dataEntre", dataEntre);

    form.setFieldsValue({
      nombre: data.data.attributes.nombre,
      departamento: JSON.parse(dataRCA.attributes.Proyecto),
      puesto: dataRCA.attributes.Puesto,
      entrevistador: dataRCA.attributes.asignadoEntrevista,
      direccion: data.data.attributes.mother.data.attributes.direccion,
      dpi: data.data.attributes.mother.data.attributes.dpi,
      celular: data.data.attributes.mother.data.attributes.celular,
      correo: data.data.attributes.mother.data.attributes.correo,
      escucha1: dataEntre.attributes.escucha1,
      escucha2: dataEntre.attributes.escucha2,
      liderazgoydesarrolloequipos1:
        dataEntre.attributes.liderazgoydesarrolloequipos1,
      liderazgoydesarrolloequipos2:
        dataEntre.attributes.liderazgoydesarrolloequipos2,
      astuciapolitica1: dataEntre.attributes.astuciapolitica1,
      astuciapolitica2: dataEntre.attributes.astuciapolitica2,
      innovacion1: dataEntre.attributes.innovacion1,
      innovacion2: dataEntre.attributes.innovacion2,
      partnering1: dataEntre.attributes.partnering1,
      partnering2: dataEntre.attributes.partnering2,
      gestionfinanciera1: dataEntre.attributes.gestionfinanciera1,
      gestionfinanciera2: dataEntre.attributes.gestionfinanciera2,
      relacionesinterpersonales1:
        dataEntre.attributes.relacionesinterpersonales1,
      relacionesinterpersonales2:
        dataEntre.attributes.relacionesinterpersonales2,
      gestioncalidadymejora1: dataEntre.attributes.gestioncalidadymejora1,
      gestioncalidadymejora2: dataEntre.attributes.gestioncalidadymejora2,
      conocimientrodigital1: dataEntre.attributes.conocimientrodigital1,
      conocimientrodigital2: dataEntre.attributes.conocimientrodigital2,
      habilidadescomunicativas1: dataEntre.attributes.habilidadescomunicativas1,
      habilidadescomunicativas2: dataEntre.attributes.habilidadescomunicativas2,
      habilidadesydestrezas: dataEntre.attributes.habilidadesydestrezas,
      debilidades: dataEntre.attributes.debilidades,
      comentarios: dataEntre.attributes.comentarios,

      tipoCuenta: dataEntre.attributes.tipoCuenta,
      familiaresPronto: dataEntre.attributes.familiaresPronto,
      fuenteCV: dataEntre.attributes.fuenteCV,
      edad: dataEntre.attributes.edad,
      direccionRecide: dataEntre.attributes.direccionRecide,
      salarioActual: dataEntre.attributes.salarioActual,
      disponibilidadInmediata: dataEntre.attributes.disponibilidadInmediata,
      tipoTransporte: dataEntre.attributes.tipoTransporte,
      principalMotivacion: dataEntre.attributes.principalMotivacion,
      queEnojar: dataEntre.attributes.queEnojar,
      padeceEnfermedad: dataEntre.attributes.padeceEnfermedad,
      descripcionMetasValores: dataEntre.attributes.descripcionMetasValores,
      nucleoFamiliar: dataEntre.attributes.nucleoFamiliar,
      estudiaActualmente: dataEntre.attributes.estudiaActualmente,
      nivelAcademico: dataEntre.attributes.nivelAcademico,
      laboradoPronto: dataEntre.attributes.laboradoPronto,
      experienciaLaboralDetallada:
        dataEntre.attributes.experienciaLaboralDetallada,
      mayorLogroLaboral: dataEntre.attributes.mayorLogroLaboral,
      comprendePuesto: dataEntre.attributes.comprendePuesto,
      problemaBanco: dataEntre.attributes.problemaBanco,
      tituloFirmado: dataEntre.attributes.tituloFirmado,
      antecedentes: dataEntre.attributes.antecedentes,
      fechasDePago: dataEntre.attributes.fechasDePago,
      verificacionPapeleria: dataEntre.attributes.verificacionPapeleria,
      headsets: dataEntre.attributes.headsets,
      pruebasPsicojob: dataEntre.attributes.pruebasPsicojob,
    });
  };
  /////Fin cara de Datos en el muro

  const EnviarOfertar = (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datosActualizar = JSON.stringify({
      data: {
        estado: "Oferta",
      },
    });

    const { dataStatus } = Axios.put(
      `https://rh.api.prontobpo.cloud/api/perfiles/${id}`,
      datosActualizar,
      { headers }
    );

    setmodalConfirmacionOferta(true);

    return dataStatus;
  };

  const back = (id) => {
    history.push({
      pathname: "/FormularioPerfil", ///Direccion a donde dirige
      search: `?id=${id}`, //parametros a enviar
      state: {
        //Estado
        update: true,
      },
    });

    return;
  };

  ////use Effect de CargaDatos
  useEffect(() => {
    cargaDatosPerfil().then((data) => {
      console.log(">>> returncargaPerfil", data);
      cargaDatosAsignados(data.data.attributes.mother.data.id).then(
        (dataRCA) => {
          console.log(">>> return Datos Asigandos", dataRCA);
          consultaManejaEquipos(data.data.attributes.mother.data.id).then(
            (dataEntre) => {
              console.log(">>> return Datos Entrevista", dataEntre);
              onFill(data, dataRCA, dataEntre);
            }
          );
        }
      );
    });
  }, []);
  ////Fin Efectos CargaDatos

  ////Retorrno Componente
  return (
    <>
      <Form
        name="solicitudEmpleo"
        form={form}
        autoComplete="off"
        requiredMark={true}
        initialValues={{}}
      >
        <Row gutter={[24, 18]} justify="space-around" align="middle">
          {
            ///Informacion personal
          }
          <Col span={23}>
            <Card bordered={false}>
              <Meta title="Formulario Maneja Equipos" />

              <br />

              <Row gutter={[16, 0]} justify="space-around" align="middle">
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Información Personal
                </Divider>

                <Col span={24}>
                  <Row gutter={[16, 0]} justify="space-around" align="middle">
                    <Col span={12}>
                      {
                        /// Primera Seccion
                      }
                      <Row gutter={[16, 0]}>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Nombre"
                            name="nombre"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tu nombre.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Nombre" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Departamento"
                            name="departamento"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa departamento.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Departamento" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Puesto"
                            name="puesto"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa puesto.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Puesto" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Entrevistador"
                            name="entrevistador"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa entrevuistador.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Entrevistador" />
                          </Form.Item>
                        </Col>

                        <Col span={20} offset={2}>
                          <Form.Item
                            className="username"
                            label="Direccion"
                            name="direccion"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa direccion.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Direccion" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Edad"
                            name="edad"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa edad.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Edad" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="DPI"
                            name="dpi"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa DPI.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="DPI" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Celular"
                            name="celular"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa Celular.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Celular" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Correo Electronico"
                            name="correo"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa correo.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Correo" />
                          </Form.Item>
                        </Col>

                        {
                          /// Segunda Seccion
                        }
                      </Row>
                    </Col>

                    <Col span={6}>
                      <Image
                        width={{ width: "100%" }}
                        src={imgSolicitudForm}
                        preview={false}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Vacante
          }

          <Col span={23}>
          <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  1. Entrevista General
                </Divider>
                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Tipo de cuenta"
                    name="tipoCuenta"
                    rules={[
                      {
                        required: true,
                        message: "Tipo Cuenta.",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Seleccione el tipo de cuenta"
                    >
                      <Option value="Aleman">Aleman</Option>
                      <Option value="Español">Español</Option>
                      <Option value="Frances">Frances</Option>
                      <Option value="Inglés">Inglés</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Fuente del CV"
                    name="fuenteCV"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese fuente de CV.",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona fuente"
                    >
                      <Option value="Correo Recruitment">
                        Correo Recruitment
                      </Option>
                      <Option value="Referido">Referido</Option>
                      <Option value="Correo Reclutamiento">
                        Correo Reclutamiento
                      </Option>
                      <Option value="LinkedIn">LinkedIn</Option>
                      <Option value="Facebook">Facebook</Option>
                      <Option value="Presencial">Presencial</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Familiares en ProntoBPO, Proyecto y relación"
                    name="familiaresPronto"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa si tienes familiares.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Familiares en pronto" />
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Ha Laborado En Pronto"
                    name="laboradoPronto"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese nivel Academico",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si ( Preguntar por recontratación y por qué se fue de la empresa)">
                        Si ( Preguntar por recontratación y por qué se fue de la
                        empresa)
                      </Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Salario Actual"
                    name="salarioActual"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa salario actual.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Salario" />
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Informar de las fechas de Pago (Salario Base el 30 y 15 solo bonos) "
                    name="fechasDePago"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Disponibilidad Inmediata"
                    name="disponibilidadInmediata"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese disponibilidad.",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                      <Option value="Tiene que dar tiempo">
                        Tiene que dar tiempo
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Tipo de Transporte"
                    name="tipoTransporte"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese tipo transporte.",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona transporte"
                    >
                      <Option value="Moto (verificar si hay parqueo antes de ofrecerlo)">
                        Moto (verificar si hay parqueo antes de ofrecerlo)
                      </Option>
                      <Option value="Carro (Mencionar de los parqueos aledaños)">
                        Carro (Mencionar de los parqueos aledaños)
                      </Option>
                      <Option value="Transporte público ( Considerar Horarios )">
                        Transporte público ( Considerar Horarios )
                      </Option>
                      <Option value="Vienen a dejar y a traer ( Considerar Horarios )">
                        Vienen a dejar y a traer ( Considerar Horarios )
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>


                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  2. Verificación de Documentos
                </Divider>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Título Firmado y Sellado por Contraloría"
                    name="tituloFirmado"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Antecedentes Tramitados (Agregar a Notas Adicionales en caso tenga problemas para Tramitarlos)"
                    name="antecedentes"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  3. Experiencia
                </Divider>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Nivel Academico"
                    name="nivelAcademico"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese nivel Academico",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Diversificado Completo">
                        Diversificado Completo
                      </Option>
                      <Option value="Licenciatura">Licenciatura</Option>
                      <Option value="Posgrado">Posgrado</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Experiencia Laboral Detallada
                            Últimos 3 empleos, funciones y motivo de salida, ultimo salario"
                    name="experienciaLaboralDetallada"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Indica tu mayor logro en tu record laboral y cómo este aporto a la organización"
                    name="mayorLogroLaboral"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Qué comprendes de la plaza a la que estás aplicando"
                    name="comprendePuesto"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  4. Motivaciones
                </Divider>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Cuál es tu principal fuente de motivación / Qué disfrutas"
                    name="principalMotivacion"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Descripción del candidato en Metas, valores y/o fortalezas."
                    name="descripcionMetasValores"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Padece de alguna Enfermedad, Alergia o Similar que requiera seguimiento"
                    name="padeceEnfermedad"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Qué es lo que más te hace enojar (profundizar)"
                    name="queEnojar"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Quiénes conforman tu núcleo familiar (profundizar)"
                    name="nucleoFamiliar"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Estudia actualmente o tiene planes de estudiar (especificar horario)"
                    name="estudiaActualmente"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  5. Otros
                </Divider>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Tiene algún problema para aperturar cuenta en Banco Industrial"
                    name="problemaBanco"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="No">No</Option>
                      <Option value="Si (Preguntar por disponibilidad de cheque a encargado del área)">
                        Si (Preguntar por disponibilidad de cheque a encargado
                        del área)
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Headset ( En caso no tenga, explicar pagos hasta 4 cuotas Q.400.00 -Q.450.00)"
                    name="headsets"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={18} offset={3}>
                  <Form.Item
                    className="username"
                    label="Pruebas Psicojob enviadas"
                    name="pruebasPsicojob"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                    </Select>
                  </Form.Item>
                </Col>


                <Col span={20} offset={2} hidden>
                  <Form.Item
                    className="username"
                    label="Dirección o zona en la que Reside"
                    name="direccionRecide"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa direccion.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Direccion" />
                  </Form.Item>
                </Col>
           

                <Col span={24} hidden>
                  <Form.Item
                    className="username"
                    label="Verificación de Papelería, según Checklist"
                    name="verificacionPapeleria"
                    rules={[
                      {
                        required: false,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                    </Select>
                  </Form.Item>
                </Col>

                

                
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  ESCUCHA
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre alguna situación en la que escuchar de manera activa te ayudó a solucionar?"
                    name="escucha1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cómo reaccionas ante un tema que no es de tu interés, pero es sumamente necesario?"
                    name="escucha2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  LIDERAZGO Y DESARROLLO DE EQUIPOS
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cómo describirías tu estilo de liderazgo?"
                    name="liderazgoydesarrolloequipos1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Qué aspectos consideras importantes para lograr el desarrollo de tu equipo de trabajo?"
                    name="liderazgoydesarrolloequipos2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  ASTUCIA POLÍTICA
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Qué cualidades consideras importantes para el manejo favorable de una negociación?"
                    name="astuciapolitica1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre alguna situación que manejaste y en la que lograste tu objetivo.?"
                    name="astuciapolitica2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  INNOVACIÓN
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Por qué consideras que es importante innovar?"
                    name="innovacion1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre algún proceso o actividad en la que hayas innovado y cómo te funcionó.?"
                    name="innovacion2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  PARTNERING
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Has creado alianzas que favorecen a la organización? Cuéntame.?"
                    name="partnering1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Qué aspectos consideras fundamentales para que se realice  de manera correcta?"
                    name="partnering2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  GESTIÓN FINANCIERA
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Qué aspectos consideras importantes para llevar una gestión financiera correcta?"
                    name="gestionfinanciera1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre alguna situación en la que hayas tenido bajo tu responsabilidad un proceso financiero y cómo lo manejaste.?"
                    name="gestionfinanciera2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  RELACIONES INTERPERSONALES
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Consideras importante mantener buenas relaciones interpersonales para el correcto funcionamiento del equipo? ¿Por qué?."
                    name="relacionesinterpersonales1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cómo influyes  dentro del equipo de trabajo para la mejora en las relaciones interpersonales?"
                    name="relacionesinterpersonales2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  GESTIÓN POR CALIDAD Y MEJORA CONTINUA
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Consideras importante supervisar las gestiones y procesos que se realizan? ¿Por qué?"
                    name="gestioncalidadymejora1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Qué aspectos considerarías para desarrollar un plan de acción de mejora?"
                    name="gestioncalidadymejora2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  CONOCIMIENTO DIGITAL Y MANEJO DE PROGRAMAS ADMINISTRATIVOS
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Consideras que utilizar herramientas digitales facilitan los procesos? ¿Por qué?"
                    name="conocimientrodigital1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre alguna herramienta digital que utilices y consideres que ha sido de ayuda para trabajar de manera más eficaz.?"
                    name="conocimientrodigital2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  HABILIDADES COMUNICATIVAS
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuando no estás de acuerdo con alguna instrucción o comentario, ¿Cómo se lo comunicas a la otra persona?"
                    name="habilidadescomunicativas1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Por qué consideras que son importantes las habilidades comunicativas?"
                    name="habilidadescomunicativas2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  PUNTOS FUERTES Y DÉBILES
                </Divider>

                <Col span={10} offset={1}>
                  <Form.Item
                    className="username"
                    label="HABILIDADES Y DESTREZAS "
                    name="habilidadesydestrezas"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa tu HABILIDADES Y DESTREZAS .",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="HABILIDADES Y DESTREZAS" />
                  </Form.Item>
                </Col>

                <Col span={10}>
                  <Form.Item
                    className="username"
                    label="DEBILIDADES"
                    name="debilidades"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa DEBILIDADES.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="DEBILIDADES" />
                  </Form.Item>
                </Col>

                <Col span={12} offset={6}>
                  <Form.Item
                    className="username"
                    label="Comentarios"
                    name="comentarios"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12} offset={6}>
                  <Row>
                    <Col span={6} offset={3}>
                      <Form.Item>
                        <Button
                          loading={false}
                          size="Large"
                          type="primary"
                          htmlType="submit"
                          onClick={() => EnviarOfertar(idRequerimiento)}
                        >
                          Confirmar Entrevista
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col span={6} offset={6}>
                      <Button
                        loading={false}
                        size="Large"
                        type="primary"
                        danger
                        onClick={denegarSolicitudEmpleo}
                      >
                        Declinar Perfil
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>

      {
        <Modal
          centered
          visible={modalSucessDenegado}
          onOk={() => history.push({ pathname: "./solicitudes/perfiles" })}
          onCancel={() => setModalSucessDenegado(false)}
          footer={
            <Button
              type="primary"
              onClick={() =>
                history.push({ pathname: "./solicitudes/perfiles" })
              }
            >
              Salir
            </Button>
          }
        >
          <Sucess titulo="Perfil denegado con exito!" />
        </Modal>
      }

      {
        ////Modal
        <Modal
          centered
          visible={modalConfirmacionOferta}
          onOk={() => back(idRequerimiento)}
          onCancel={() => back(idRequerimiento)}
          footer={
            <Button type="primary" onClick={() => back(idRequerimiento)}>
              Salir
            </Button>
          }
        >
          <Sucess titulo="Se confirma el candidato para el proceso de oferta" />
        </Modal>
      }
    </>
  );
};

export default ConfirmarFormularioManejaEquipos;
